<i18n locale="th" lang="yaml" >
card.recent_ticket.title : "Recent Tickets"
card.progress_ticket.title : "Ticket ที่อยู่ระหว่างดำเนินการ"
card.pending_ticket.title : "Pending Tickets"
see_more.label : "ดูเพิ่ม"
remark.description : "* แสดง 6 รายการล่าสุดเท่านั้น"
</i18n>

<template>
	<div class="page page-padding">
		<div class="ticket-section">
			<div class="ticket-section-header">
				<label>{{$t('card.progress_ticket.title')}}</label>
				<router-link v-show="progressTicketDataList.length > 0" :to="progressMoreLink" class="ticket-section-action">
					{{$t('see_more.label')}}
				</router-link>
			</div>
			<div class="ticket-section-content">
				<MyCardList
				:num-skeletons="3"
				:empty="progressTicketDataList.length === 0"
				class="mycard-list-grid ticket-progress"
				:loading="progressLoading">
					<TicketLiteCard
						slot="skeleton"
						:bordered="true"
						size="small"
						:loading="true" />
					<TicketLiteCard v-for="ticketData of progressTicketDataList"
						:key="ticketData.id"
						:ticket-data="ticketData"
						size="small"
						:bordered="true"
						link-target="_blank"
						:clickable="false"/>
				</MyCardList>
				<div v-show="$notEmpty(progressTicketDataList)" class="ticket-section-remark">
					{{$t('remark.description')}}
				</div>
			</div>
		</div>
		<div class="ticket-section-divider" />
		<div class="ticket-section">
			<div class="ticket-section-header">
				<label>{{$t('card.pending_ticket.title')}}</label>
				<router-link v-show="pendingTicketDataList.length > 0" :to="pendingMoreLink" class="ticket-section-action">
					{{$t('see_more.label')}}
				</router-link>
			</div>
			<div class="ticket-section-content">
				<MyCardList
					:num-skeletons="3"
					:empty="pendingTicketDataList.length === 0"
					class="mycard-list-grid ticket-pending"
					:loading="pendingLoading">
					<TicketLiteCard
						slot="skeleton"
						:bordered="true"
						size="small"
						:loading="true" />
					<TicketLiteCard v-for="ticketData of pendingTicketDataList"
						:key="ticketData.id"
						:ticket-data="ticketData"
						size="small"
						:bordered="true"
						link-target="_blank"
						:clickable="false"/>
				</MyCardList>
				<div v-show="$notEmpty(pendingTicketDataList)" class="ticket-section-remark">
					{{$t('remark.description')}}
				</div>
			</div>
		</div>
		<div class="ticket-section-divider" />
		<div class="ticket-section">
			<div class="ticket-section-header">
				<label>{{$t('card.recent_ticket.title')}}</label>
				<router-link v-show="recentTicketDataList.length > 0" :to="recentMoreLink" class="ticket-section-action">
					{{$t('see_more.label')}}
				</router-link>
			</div>
			<div class="ticket-section-content">
				<MyCardList
					:num-skeletons="3"
					:empty="recentTicketDataList.length === 0"
					class="mycard-list-grid ticket-recents"
					:loading="recentLoading">
					<TicketLiteCard
						slot="skeleton"
						:bordered="true"
						size="small"
						:loading="true" />
					<TicketLiteCard v-for="ticketData of recentTicketDataList"
						:key="ticketData.id"
						:ticket-data="ticketData"
						size="small"
						:bordered="true"
						link-target="_blank"
						:clickable="false"/>
				</MyCardList>
				<div v-show="$notEmpty(recentTicketDataList)" class="ticket-section-remark">
					{{$t('remark.description')}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MyCardList from "@components/common/MyCardList.vue"
import TicketLiteCard from "@components/ticket/TicketLiteCard.vue"
import axios from "axios"
import ApiError from "@utils/errors/ApiError"
import {fulfillTicket} from "@utils/objectUtil"
export default {
	components : {
		MyCardList, TicketLiteCard,
	} ,
	props : {
		filterName : {
			type : String,
			default : undefined,
		} ,
		filterValue : {
			type : null,
			default : undefined,
		} ,
		progressMoreLink : {
			type : null,
			default : () => []
		} ,
		recentMoreLink : {
			type : null,
			default : () => []
		} ,
		pendingMoreLink : {
			type : null,
			default : () => []
		}
	} ,
	data() {
		return {
			recentTicketDataList : [] ,
			recentLoading : false,
			progressTicketDataList : [] ,
			progressLoading : false,
			pendingTicketDataList : [] ,
			pendingLoading : false,
		}
	} ,
	watch : {
		filterName(){
			this.fetchData()
		} ,
		filterValue() {
			this.fetchData()
		}
	},
	mounted() {
		this.fetchData()
	} ,
	methods : {
		fetchData() {
			if (this.filterName && !this.filterValue) {
				return;
			}
			this.fetchRecentTicket()
			this.fetchPendingTicket()
			this.fetchProgressTicket()
		} ,
		fetchRecentTicket() {
			this.recentLoading = true
			const search = {
				page_size : 6 ,
				current_page : 1,
			}
			const filter = {
				status_list : ['close','resolved'] ,
			}
			if (this.filterName) {
				filter[this.filterName] = this.filterValue
			}
			axios.get("/api/tickets/search",{params : {search,filter}}).then((response)=>{
				const bucket = response.data.data.bucket

				this.recentTicketDataList = response.data.data.tickets.map((ticket)=>{
					return fulfillTicket(ticket,bucket)
				})
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.recentLoading = false
			})
		} ,
		fetchPendingTicket() {
			this.pendingLoading = true
			const search = {
				page_size : 0 ,
				current_page : 1,
			}
			const filter = {
				status_list : ['pending'] ,
			}
			if (this.filterName) {
				filter[this.filterName] = this.filterValue
			}
			axios.get("/api/tickets/search",{params : {search,filter}}).then((response)=>{
				const bucket = response.data.data.bucket

				this.pendingTicketDataList = response.data.data.tickets.map((ticket)=>{
					return fulfillTicket(ticket,bucket)
				})
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.pendingLoading = false
			})
		} ,
		fetchProgressTicket() {
			this.progressLoading = true
			const search = {
				page_size : 0 ,
				current_page : 1,
			}
			const filter = {
				status_list : ['new','investigate','reopen'] ,
			}
			if (this.filterName) {
				filter[this.filterName] = this.filterValue
			}
			axios.get("/api/tickets/search",{params : {search,filter}}).then((response)=>{
				const bucket = response.data.data.bucket

				this.progressTicketDataList = response.data.data.tickets.map((ticket)=>{
					return fulfillTicket(ticket,bucket)
				})
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.progressLoading = false
			})
		}
	}
}
</script>
<style lang="less" scoped>
.ticket-section {
	margin-bottom : 24px;

	.mobile & {
		box-shadow : @shadow-2;
		margin : 0 auto 24px;
		max-width: 500px;
		padding : 8px 16px;
	}
}
.ticket-section-divider {
	border-bottom : 1px solid @border-color-base;
	margin-bottom : 24px;
	.mobile & {
		display : none;
	}
}
.ticket-section-header {
	padding-bottom : 4px;
	margin-bottom : 8px;
	label {
		font-family: @font-family-title;
		font-size : 1.2em;
	}
	.ticket-section-action {
		float : right;
	}
}
.ticket-section-remark {
	font-size : 0.9em;
	color : @text-muted;
	margin-top : 8px;
}
</style>
